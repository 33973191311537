/**
 * @generated SignedSource<<7be6b0b604e4d48231d73840f2b7c512>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PackageInfosTopSection_data$data = {
  readonly fileSize?: any;
  readonly moduleInterfaces?: ReadonlyArray<{
    readonly interface: {
      readonly name: string;
    };
  }>;
  readonly package: {
    readonly appTemplates: {
      readonly totalCount: number | null | undefined;
    };
    readonly icon: string;
    readonly id: string;
    readonly likersCount: number;
    readonly owner: {
      readonly globalName: string;
    };
    readonly packageName: string;
    readonly private: boolean;
    readonly showDeployButton: boolean;
    readonly viewerHasLiked: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CreateAppButtonPackage_data">;
  };
  readonly version?: string;
  readonly " $fragmentType": "PackageInfosTopSection_data";
};
export type PackageInfosTopSection_data$key = {
  readonly " $data"?: PackageInfosTopSection_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PackageInfosTopSection_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PackageInfosTopSection_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Package",
      "kind": "LinkedField",
      "name": "package",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAppButtonPackage_data"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AppTemplateConnection",
          "kind": "LinkedField",
          "name": "appTemplates",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "packageName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "owner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "globalName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "private",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "likersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "viewerHasLiked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showDeployButton",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "version",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileSize",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InterfaceVersion",
          "kind": "LinkedField",
          "name": "moduleInterfaces",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Interface",
              "kind": "LinkedField",
              "name": "interface",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "PackageVersion",
      "abstractKey": null
    }
  ],
  "type": "PackageInstance",
  "abstractKey": "__isPackageInstance"
};

(node as any).hash = "47faed6fb32ea3fbfb08b3b1d09fd87e";

export default node;
