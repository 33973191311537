import React from "react";

const RuntimeLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      viewBox="0 0 16 22"
      fill="none"
      {...props}
      className={props?.className}
    >
      <g filter="url(#filter0_i_5448_212637)">
        <path d="M12 4.125L2.375 4.125" stroke="#02C372" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#filter1_i_5448_212637)">
        <path d="M15 11L5.375 11" stroke="#02C372" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g filter="url(#filter2_i_5448_212637)">
        <path
          d="M11 17.875L1.375 17.875"
          stroke="#02C372"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_5448_212637"
          x="1.375"
          y="3.125"
          width="11.625"
          height="2.72739"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.727387" />
          <feGaussianBlur stdDeviation="0.363693" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_5448_212637" />
        </filter>
        <filter
          id="filter1_i_5448_212637"
          x="4.375"
          y="10"
          width="11.625"
          height="2.72739"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.727387" />
          <feGaussianBlur stdDeviation="0.363693" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_5448_212637" />
        </filter>
        <filter
          id="filter2_i_5448_212637"
          x="0.375"
          y="16.875"
          width="11.625"
          height="2.72739"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.727387" />
          <feGaussianBlur stdDeviation="0.363693" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_5448_212637" />
        </filter>
      </defs>
    </svg>
  );
};

export default RuntimeLogo;
