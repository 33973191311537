import React from "react";

const CopyDocument = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
      className={props?.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 4H0.75H0V4.75V14.75V15.5H0.75H10.75H11.5V14.75V12.75H10V14H1.5V5.5H2.75V4Z"
        fill="#FBFBFC"
      />
      <rect x="4.75" y="0.75" width="10" height="10" stroke="#FBFBFC" strokeWidth="1.5" />
    </svg>
  );
};

export default CopyDocument;
