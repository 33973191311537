import React from "react";

const DeployableIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.666992" y="0.129883" width="24" height="24" rx="12" fill="#0F0518" fillOpacity="0.1" />
      <g opacity="0.5">
        <path
          d="M11.3975 9.36043L12.0321 8.20658L12.6667 7.05273L13.3013 8.20658L13.9359 9.36043L14.5705 10.5143"
          stroke="#0F0518"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.475 13.9761L17.1096 15.1299L17.7442 16.2838H16.475H15.2058H13.9365"
          stroke="#0F0518"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1283 16.2838H8.85907H7.58984L8.22446 15.13L8.85907 13.9761L9.49369 12.8223"
          stroke="#0F0518"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="0.666992" y="0.129883" width="24" height="24" rx="12" stroke="#FAFBFD" />
    </svg>
  );
};
export default DeployableIcon;
