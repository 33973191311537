import React from "react";

interface PackageTagProps {
  content: string;
}
const PackageTag: React.FC<PackageTagProps> = ({ content }) => {
  return (
    <p className="rounded-[4px] bg-[#0F05184D]/30 py-[4px] px-[6px] text-[13px] font-[600] uppercase tracking-[0.91px] text-[#FBFBFC]">
      {content}
    </p>
  );
};

export default PackageTag;
