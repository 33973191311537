/**
 * @generated SignedSource<<47197406f00003282bdd733b8e9a4f07>>
 * @relayHash ebd1439e560cfec5cdf0a5ea999ad3e0
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ebd1439e560cfec5cdf0a5ea999ad3e0

import { ConcreteRequest, Query } from 'relay-runtime';
export type PackageVersionsDropdownLazy_PackageVersionQuery$variables = {
  name: string;
  version?: string | null | undefined;
};
export type PackageVersionsDropdownLazy_PackageVersionQuery$data = {
  readonly getPackageVersion: {
    readonly activeVersion: string;
    readonly package: {
      readonly versions: ReadonlyArray<{
        readonly createdAt: any;
        readonly id: string;
        readonly publishedBy: {
          readonly avatar: string;
          readonly fullName: string;
          readonly username: string;
        };
        readonly version: string;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export type PackageVersionsDropdownLazy_PackageVersionQuery = {
  response: PackageVersionsDropdownLazy_PackageVersionQuery$data;
  variables: PackageVersionsDropdownLazy_PackageVersionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": "latest",
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "version",
    "variableName": "version"
  }
],
v2 = {
  "alias": "activeVersion",
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "version",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PackageVersionsDropdownLazy_PackageVersionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PackageVersion",
        "kind": "LinkedField",
        "name": "getPackageVersion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Package",
            "kind": "LinkedField",
            "name": "package",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageVersion",
                "kind": "LinkedField",
                "name": "versions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "publishedBy",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PackageVersionsDropdownLazy_PackageVersionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PackageVersion",
        "kind": "LinkedField",
        "name": "getPackageVersion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Package",
            "kind": "LinkedField",
            "name": "package",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PackageVersion",
                "kind": "LinkedField",
                "name": "versions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "publishedBy",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ebd1439e560cfec5cdf0a5ea999ad3e0",
    "metadata": {},
    "name": "PackageVersionsDropdownLazy_PackageVersionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "bb4db40eef9f92b337c478332b00948c";

export default node;
