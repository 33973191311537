import React from "react";

import { cn } from "@libs/helper";

import styles from "./index.module.css";

const TopTriangle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("fill-[#19191A]", props?.className)}
    >
      <path d="M12 5.438h-.063a2 2 0 01-1.537-.72L6.768.36a1 1 0 00-1.536 0L1.6 4.718a2 2 0 01-1.537.72H0h12z" />
    </svg>
  );
};

interface TooltipCardProps {
  content: string;
  className?: string;
}

const TooltipCard: React.FC<TooltipCardProps> = ({ className, content }) => {
  return (
    <div className={cn(styles.wrapper, className)}>
      <TopTriangle className={styles.triangle} />
      <div className={styles.contentWrapper}>
        <p className={styles.content}>{content}</p>
      </div>
    </div>
  );
};

export default TooltipCard;
