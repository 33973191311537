import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Arrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("fill-black", props?.className))}
    >
      <path d="M10 20L0 10 10 0l1.775 1.775L3.55 10l8.225 8.225L10 20z" />
    </svg>
  );
};

export default Arrow;
