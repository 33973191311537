import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const EdgeLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#AC5CE0]", props?.className))}
    >
      <g filter="url(#prefix__filter0_i_1575_38915)">
        <path
          d="M6.25 5.13l.875-1.59L8 1.947l.875 1.591.875 1.591.875 1.591"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#prefix__filter1_i_1575_38915)">
        <path
          d="M13.25 11.494l.875 1.591.875 1.591H9.75"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g filter="url(#prefix__filter2_i_1575_38915)">
        <path
          d="M4.5 14.675H1l.875-1.59.875-1.592.875-1.59"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="prefix__filter0_i_1575_38915"
          x={5.25}
          y={0.948}
          width={6.375}
          height={7.446}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={0.673} />
          <feGaussianBlur stdDeviation={0.337} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_1575_38915" />
        </filter>
        <filter
          id="prefix__filter1_i_1575_38915"
          x={8.75}
          y={10.494}
          width={7.25}
          height={5.855}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={0.673} />
          <feGaussianBlur stdDeviation={0.337} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_1575_38915" />
        </filter>
        <filter
          id="prefix__filter2_i_1575_38915"
          x={0}
          y={8.902}
          width={5.5}
          height={7.446}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={0.673} />
          <feGaussianBlur stdDeviation={0.337} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect1_innerShadow_1575_38915" />
        </filter>
      </defs>
    </svg>
  );
};

export default EdgeLogo;
