import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

import styles from "./index.module.css";
import { linkingType } from "@components/PackageCard/PackageTag";
import Link from "next/link";

interface PackageNameProps {
  className?: string;
  owner: string;
  name: string;
  linking?: linkingType;
}
const PackageName: React.FC<PackageNameProps> = ({ name, owner, linking = "package", className }) => {
  switch (linking) {
    case "package":
      return (
        <Link
          href={`/${owner}/${name}`}
          className={twMerge(clsx(styles.wrapper, className))}
          data-cy={`pkg-name-${owner}/${name}`}
        >
          {owner}
          <span className={styles.separator}>/</span>
          {name}
        </Link>
      );
    case "owner-package":
      return (
        <div className={twMerge(clsx(styles.wrapper, className))} data-cy={`pkg-name-${owner}/${name}`}>
          <Link href={`/${owner}`}>{owner}</Link>
          <span className={styles.separator}>/</span>
          <Link href={`/${owner}/${name}`}>{name}</Link>
        </div>
      );
    case "nolink":
    default:
      return (
        <div className={twMerge(clsx(styles.wrapper, className))} data-cy={`pkg-name-${owner}/${name}`}>
          {owner}
          <span className={styles.separator}>/</span>
          {name}
        </div>
      );
  }
};

export default PackageName;
