import { formatDistance } from "date-fns";
import React from "react";

import UserAvatarIcon from "@components/UserAvatarIcon";
import { cn } from "@libs/helper";

import styles from "./PackageVersionsDropdownLazy.module.css";

const RadioUnSelected = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={cn("stroke-[#0F0518]", props?.className)}
    >
      <circle cx={9} cy={9} r={8.5} strokeOpacity={0.2} />
    </svg>
  );
};
const RadioSelected = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={props?.className}
    >
      <circle cx={9} cy={9} r={8.25} stroke="#0F0518" strokeWidth={1.5} />
      <circle cx={9} cy={9} r={5} fill="#0F0518" />
    </svg>
  );
};

interface DropdownOptionProps {
  selected?: boolean;
  versionNumber: string;
  date: string;
  publisherAvatar: string;
  publisherUsername: string;
  dataCy: string;
}
const DropdownOption: React.FC<DropdownOptionProps> = ({
  selected,
  versionNumber,
  date,
  publisherAvatar,
  publisherUsername,
  dataCy,
}) => {
  const dateDiff = formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  }).replace("about ", "");
  return (
    <div className={styles.versionDropdown} data-cy={dataCy}>
      <div className={styles.versionOption}>
        {selected ? <RadioSelected className={styles.radioIcon} /> : <RadioUnSelected className={styles.radioIcon} />}
        <span className={styles.versionNumber} data-cy="pkg-semver">
          {versionNumber}
        </span>
      </div>
      <div className={styles.versionOption}>
        <span className={styles.date}>{dateDiff}</span>
        <UserAvatarIcon src={publisherAvatar} username={publisherUsername} tooltipPosition="left" />
      </div>
    </div>
  );
};

export default DropdownOption;
