import clsx from "clsx";
import React from "react";
import { twMerge } from "tailwind-merge";

const Like = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={twMerge(clsx("stroke-[#0F0518]", props?.className))}
    >
      <path
        d="M8.992 15l-6.81-7.098a4.046 4.046 0 012.206-6.85 4.025 4.025 0 013.499 1.132L9 3.3l1.113-1.116a4.033 4.033 0 013.499-1.132 4.03 4.03 0 012.962 2.183 4.052 4.052 0 01-.756 4.667A7386.11 7386.11 0 018.992 15z"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Like;
