import React from "react";

interface ThreeDotsIconInterface {
  svgFill?: string;
  stroke?: string;
  fill?: string;
  width?: number;
  height?: number;
  className?: string;
}
const ThreeDotsIcon = ({
  width = 13,
  height = 13,
  svgFill = "none",
  fill = "#0F0518",
  className = "",
}: ThreeDotsIconInterface) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill={svgFill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <circle cx="1.5" cy="1.5" r="1.5" fill={fill} />
        <circle cx="6.5" cy="1.5" r="1.5" fill={fill} />
        <circle cx="11.5" cy="1.5" r="1.5" fill={fill} />
      </g>
    </svg>
  );
};
export default ThreeDotsIcon;
