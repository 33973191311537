import React from "react";

import EdgeLogo from "@assets/icons/EdgeLogo";

import styles from "./index.module.css";

interface CreateAppButtonSilentInterface {
  onClick?: () => void;
}
const CreateAppButtonSilent = ({ onClick = () => {} }: CreateAppButtonSilentInterface) => {
  return (
    <button onClick={onClick} className={styles.wrapper} data-cy="create-app-button">
      <EdgeLogo className="w-[14px]" />
      <span className={styles.text}>Deploy</span>
    </button>
  );
};
export default CreateAppButtonSilent;
