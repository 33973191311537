import React from "react";

import { cn } from "@libs/helper";

import styles from "./index.module.css";

interface ContentCardProps {
  children: React.ReactNode;
  className?: string;
}
const ContentCard: React.FC<ContentCardProps> = ({ children, className, ...restProps }) => {
  return (
    <div {...restProps} className={cn(styles.wrapper, className)}>
      {children}
    </div>
  );
};

export default ContentCard;
