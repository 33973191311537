/**
 * @generated SignedSource<<800455b8f5e3e8043b61d6000ec5c5e6>>
 * @relayHash 2423b5c8554f55476cccce5c1ce468d7
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2423b5c8554f55476cccce5c1ce468d7

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LikePackageInput = {
  clientMutationId?: string | null | undefined;
  packageId: string;
};
export type PackageInfosTopSection_LikeMutation$variables = {
  input: LikePackageInput;
};
export type PackageInfosTopSection_LikeMutation$data = {
  readonly likePackage: {
    readonly package: {
      readonly id: string;
      readonly likersCount: number;
      readonly viewerHasLiked: boolean;
    };
  } | null | undefined;
};
export type PackageInfosTopSection_LikeMutation = {
  response: PackageInfosTopSection_LikeMutation$data;
  variables: PackageInfosTopSection_LikeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LikePackagePayload",
    "kind": "LinkedField",
    "name": "likePackage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Package",
        "kind": "LinkedField",
        "name": "package",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "likersCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerHasLiked",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PackageInfosTopSection_LikeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PackageInfosTopSection_LikeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "2423b5c8554f55476cccce5c1ce468d7",
    "metadata": {},
    "name": "PackageInfosTopSection_LikeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b3ace03da8bce1a2b67f9a1699a5123a";

export default node;
