"use client";

import { usePathname, useRouter } from "next/navigation";
// import { parseCookies } from "nookies";
import React, { Fragment, useState } from "react";

import Arrow from "@assets/Arrow";
import NewLoading from "@components/NewLoading";
import ContentCard from "@components/shared/Cards/ContentCard";
import { PackageVersionsDropdownLazy_PackageVersionQuery } from "@generated/PackageVersionsDropdownLazy_PackageVersionQuery.graphql";
import { Listbox, Transition } from "@headlessui/react";
// import initEnvironment from "@libs/createRelayEnvironment";
import { changePackageVersionInURL } from "@libs/helper";

import DropdownOption from "./DropdownOption";
import styles from "./PackageVersionsDropdownLazy.module.css";

import {
  fetchQuery,
  graphql,
  useRelayEnvironment
} from "react-relay";

const PackageVersionsDropdown_Query = graphql`
  query PackageVersionsDropdownLazy_PackageVersionQuery($name: String!, $version: String = "latest") {
    getPackageVersion(name: $name, version: $version) {
      activeVersion: version
      package {
        versions {
          id
          version
          createdAt
          publishedBy {
            avatar
            username
            fullName
          }
        }
      }
    }
  }
`;

interface PackageVersionsDropdownLazyInterface {
  packageName: string;
  version?: string;
}
const PackageVersionsDropdownLazy = ({
  packageName,
  version = "latest",
}: PackageVersionsDropdownLazyInterface) => {
  const pathname = usePathname(),
    router = useRouter();

  const [loading, setLoading] = useState(false);
  const environment = useRelayEnvironment();
  const [activeVersion, setActiveVersion] = useState(version);
  // const [versionsList, setVersionsList] = useState<PackageVersionsDropdownLazy_PackageVersionQuery$data | undefined>();
  const [versionsList, setVersionsList] = useState<any | undefined>();

  const handleChange = (value: string) => {
    const newLink = changePackageVersionInURL(pathname, value);
    // router.push(newLink).then(() => {
    //   setActiveVersion(value);
    // });
    router.push(newLink);
    setActiveVersion(value);
  };

  const loadVersions = async () => {
    setLoading(true);
    const versions = await fetchQuery<PackageVersionsDropdownLazy_PackageVersionQuery>(
      environment,
      PackageVersionsDropdown_Query,
      { name: packageName, version }
    ).toPromise();
    setVersionsList(versions);
    setLoading(false);
  };

  return (
    <Listbox
      as="div"
      value={activeVersion}
      onChange={handleChange}
      className={styles.menu}
      data-cy="pkg-dropdown-versions"
    >
      <div>
        <Listbox.Button className={styles.menuButton} data-cy="pkg-dropdown-versions-btn" onClick={loadVersions}>
          <span className={styles.versionText}>{activeVersion}</span>
          <Arrow className={styles.arrowIcon} />
        </Listbox.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Listbox.Options className={styles.menuItems}>
          <ContentCard className={styles.contentCard}>
            {versionsList?.getPackageVersion?.package.versions.map((version: any) => {
              return (
                <Listbox.Option key={`${version?.id}`} value={version?.version} className="w-full">
                  {({ selected }) => (
                    <DropdownOption
                      selected={selected}
                      versionNumber={version?.version!}
                      date={version?.createdAt!}
                      publisherAvatar={version?.publishedBy?.avatar!}
                      publisherUsername={version?.publishedBy?.fullName || version?.publishedBy?.username!}
                      dataCy="pkgversion-item"
                    />
                  )}
                </Listbox.Option>
              );
            })}

            {loading && (
              <div className="flex w-full min-w-[281px] flex-row items-center justify-center">
                <NewLoading />
              </div>
            )}
          </ContentCard>
        </Listbox.Options>
      </Transition>
    </Listbox>
  );
};

export default PackageVersionsDropdownLazy;
