import clsx from "clsx";
import useCopyToClipboard from "@hooks/useCopyToClipboard";
import React from "react";

import CopyDocumentIcon from "@assets/CopyDocument";
import RuntimeLogo from "@assets/RuntimeLogo";
import TooltipCard from "@components/TooltipCard";
import { ToasterAlertDefault } from "@components/shared/ToasterAlert";

import css from "./WasmerRunCommandBtn.module.css";

interface CustomButtonProps {
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
}
const CustomButton: React.FC<CustomButtonProps> = ({ children, className, ...otherProps }) => {
  return (
    <div
      {...otherProps}
      className={clsx(
        "flex h-full items-center justify-center  gap-3 py-0 text-[16px] font-[600] leading-[140%]",
        className
      )}
    >
      {children}
    </div>
  );
};

interface WasmerRunCommandInterface {
  wasmerRunCommand: string;
  className?: string;
}

const WasmerRunCommandBtn = ({ wasmerRunCommand = "", className }: WasmerRunCommandInterface) => {
  const [_, copy, isCopied] = useCopyToClipboard();

  const handleCopy = () => {
    copy(wasmerRunCommand);
    ToasterAlertDefault({ message: "Copied to clipboard!" });
  };

  return (
    <div className={clsx(css.wrapper, className)} data-cy="wasmer-run-btn">
      <div className={css.runCmdWrap}>
        <div className="max-h-[22px] min-h-[22px] min-w-[16px] max-w-[16px]">
          <RuntimeLogo />
        </div>
        <div className={css.runCmd}>{wasmerRunCommand}</div>
      </div>

      <CustomButton className="relative cursor-pointer  pl-[12px] text-white " onClick={handleCopy} data-cy="custom-btn">
        <CopyDocumentIcon />
        {!isCopied ? null : <TooltipCard content="Copied" className={css.copyTooltip} />}
      </CustomButton>
    </div>
  );
};

export default WasmerRunCommandBtn;
