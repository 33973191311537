import clsx from "clsx";
import { usePathname, useRouter } from "next/navigation";
import React, { useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";
import { type PayloadError } from "relay-runtime";

import DeployableIcon from "@assets/icons/DeployableIcon";
import ThreeDotsIcon from "@assets/icons/ThreeDotsIcon";
import PackageTag, { linkingType } from "@components/PackageCard/PackageTag";
import { LikeBtn } from "@components/PackagePage/PackageInfosTopSection";
import IconButton from "@components/shared/Buttons/IconButton";
import Tooltip from "@components/shared/Tooltip";
import { PackageCard_data$data, PackageCard_data$key } from "@generated/PackageCard_data.graphql";
import { cn } from "@libs/helper";

import ContentCard from "../shared/Cards/ContentCard";
import styles from "./PackageCard.module.css";
import Image from "next/image";

export const typeResultPackageVersion = "PackageVersion";
const nbCollabAvatars = 2;

const PackageCard_dataFragment = graphql`
  fragment PackageCard_data on PackageVersion {
    typeResult: __typename
    description
    package {
      id
      icon
      packageName
      likersCount
      viewerHasLiked
      showDeployButton
      owner {
        globalName
      }
      collaborators {
        totalCount
        edges {
          node {
            id
            user {
              avatar
              username
              fullName
            }
          }
        }
      }
    }
  }
`;

interface PackageCardProps {
  fragmentRef: PackageCard_data$key;
  className?: string;
  showStandaloneNameOnTablet?: boolean;
  showStandaloneNameOnAllScreens?: boolean;
  forceMobileLayout?: boolean;
  linking?: linkingType;
  hideLikeBtn?: boolean;
}
const PackageCard: React.FC<PackageCardProps> = ({
  fragmentRef,
  className,
  hideLikeBtn = false,
  linking = "package",
  forceMobileLayout = false,
}) => {
  const data = useFragment(PackageCard_dataFragment, fragmentRef);

  const router = useRouter(),
    pathname = usePathname();

  const icon = data?.package?.icon,
    name = data?.package?.packageName,
    owner = data?.package?.owner?.globalName,
    description = data?.description;

  // const handleDeployBtnClick = () => {
  //   router.push({
  //     pathname: "/apps/configure",
  //     query: { ...router.query, package: name },
  //   });
  // };

  const [likePkg, _likingPkg] = useMutation(graphql`
    mutation PackageCard_LikeMutation($input: LikePackageInput!) {
      likePackage(input: $input) {
        package {
          id
          likersCount
          viewerHasLiked
        }
      }
    }
  `);

  const [unlikePkg, _unlikingPkg] = useMutation(graphql`
    mutation PackageCard_UnlikeMutation($input: UnlikePackageInput!) {
      unlikePackage(input: $input) {
        package {
          id
          likersCount
          viewerHasLiked
        }
      }
    }
  `);

  const redirectIfErrors = (errors: PayloadError[] | null) => {
    if (errors && errors[0]?.message.includes("logged in")) {
      router.push(`/login?redirect=${pathname}`);
    }
  };

  const handleLikePackage = useCallback(() => {
    likePkg({
      variables: {
        input: {
          packageId: data?.package?.id,
        },
      },
      optimisticUpdater: (store) => {
        if (!store.getRoot().getLinkedRecord("viewer")) {
          return;
        }
        const record = store.get(data?.package?.id);
        if (!record) return;
        const currentLikeCount = (record.getValue("likersCount") as number) || 0;
        const viewerHasLiked = record.getValue("viewerHasLiked") || false;

        if (!viewerHasLiked) {
          record.setValue(currentLikeCount + 1, "likersCount");
          record.setValue(true, "viewerHasLiked");
        }
      },
      onCompleted(_response, errors) {
        redirectIfErrors(errors);
      },
    });
  }, [likePkg, data]);

  const handleUnlikePackage = useCallback(() => {
    unlikePkg({
      variables: {
        input: {
          packageId: data?.package?.id,
        },
      },
      optimisticUpdater: (store) => {
        if (!store.getRoot().getLinkedRecord("viewer")) {
          return;
        }
        const record = store.get(data?.package?.id);
        if (!record) return;
        const currentLikeCount = (record.getValue("likersCount") as number) || 0;
        const viewerHasLiked = record.getValue("viewerHasLiked") || false;

        if (viewerHasLiked) {
          record.setValue(currentLikeCount - 1, "likersCount");
          record.setValue(false, "viewerHasLiked");
        }
      },
      onCompleted(_response, errors) {
        redirectIfErrors(errors);
      },
    });
  }, [unlikePkg, data]);

  const handleLikeClick = () => {
    if (data?.package?.viewerHasLiked) {
      handleUnlikePackage();
    } else {
      handleLikePackage();
    }
  };

  if (!data?.package?.id) {
    return null;
  }
  return (
    <ContentCard className={cn(styles.wrapper, className)}>
      <div className={styles.extMenu}>
        <IconButton icon={<ThreeDotsIcon />} className={styles.tmp_hidden} />
      </div>
      <div className={styles.cardContent}>
        <div className={styles.cardHeader}>
          <div
            className={clsx(styles.packageInfo, {
              [styles.packageInfoMobile]: forceMobileLayout,
            })}
          >
            <PackageTag icon={icon} owner={owner} packageName={name} iconSize={32} linking={linking} />
            {/* {!hideLikeBtn && (
              <IconButton
                icon={
                  <LikeIcon
                    className={clsx(styles.likeIcon, {
                      [styles.hasLiked]: data?.package?.viewerHasLiked,
                    })}
                  />
                }
                className={clsx(styles.likeBtn, {
                  [styles.hasLikedBtn]: data?.package?.viewerHasLiked,
                })}
                onClick={handleLikeClick}
              />
            )} */}
            {!hideLikeBtn && (
              <LikeBtn
                likersCount={data?.package?.likersCount}
                liked={data?.package?.viewerHasLiked}
                onClick={handleLikeClick}
              />
            )}
          </div>
        </div>

        <div className={styles.descWrap}>
          <div className={styles.description}>{description}</div>

          <PkgInfoBlock
            deployable={data?.package?.showDeployButton}
            likes={data?.package?.likersCount}
            collaborators={data?.package?.collaborators}
            className={clsx(
              { [styles.pkgInfoDesktop]: !forceMobileLayout },
              { [styles.pkgInfoDesktopForceMobile]: forceMobileLayout }
            )}
          />
        </div>
      </div>

      <PkgInfoBlock
        deployable={data?.package?.showDeployButton}
        likes={data?.package?.likersCount}
        collaborators={data?.package?.collaborators}
        className={clsx(
          { [styles.pkgInfoMobile]: !forceMobileLayout },
          { [styles.pkgInfoMobileForceMobile]: forceMobileLayout }
        )}
      />
    </ContentCard>
  );
};

export default PackageCard;

interface PkgInfoBlockInterface {
  likes?: number;
  collaborators: PackageCard_data$data["package"]["collaborators"];
  className?: string;
  deployable?: boolean;
}

export const PkgInfoBlock = ({ collaborators, deployable = false, className }: PkgInfoBlockInterface) => {
  const visibleCollaboratorAvatars = collaborators?.edges?.slice(0, nbCollabAvatars);
  return (
    <div className={clsx(styles.deployedBySection, className)}>
      {/* {
        (likes > 0) && <div className={styles.likes}>
          <LikeIcon className="opacity-50" /><span className={styles.collaboratorText}>{likes}</span>
        </div>
      } */}

      <div className={styles.collaborators}>
        {visibleCollaboratorAvatars?.length > 0 ? (
          <div className={styles.collaboratorIconGroup}>
            {collaborators?.edges?.slice(0, nbCollabAvatars).map((collab, idx) => {
              const isFirst = idx === 0;
              return (
                <Tooltip
                  key={`${idx}_${collab?.node?.id}`}
                  position="bottom"
                  msg={collab?.node?.user.fullName || collab?.node?.user.username!}
                >
                  <Image
                    src={collab?.node?.user.avatar!}
                    alt={collab?.node?.user.fullName || collab?.node?.user.username + " avatar"}
                    //title={collab?.node?.user.fullName || collab?.node?.user.username + "'s avatar"}
                    width={24}
                    height={24}
                    className={cn(styles.collaboratorIcon, !isFirst && styles.overlappedCollaboratorIcon)}
                    crossOrigin="anonymous"
                  />
                </Tooltip>
              );
            })}
          </div>
        ) : null}
        {collaborators?.totalCount! > nbCollabAvatars && (
          <div className={styles.collaboratorText}>+{collaborators?.totalCount! - nbCollabAvatars}</div>
        )}
        {!deployable ? null : (
          <div className={styles.deployableWrap}>
            <DeployableIcon /> <span className={styles.label}>Deployable</span>
          </div>
        )}
      </div>
    </div>
  );
};
