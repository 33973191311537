import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useCallback } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import Like from "@assets/Like";
import { linkingType } from "@components/PackageCard/PackageTag";
import CreateAppButtonSilent from "@components/shared/Buttons/CreateAppButton/CreateAppButtonSilent";
import {
  PackageInfosTopSection_data$data,
  PackageInfosTopSection_data$key,
} from "@generated/PackageInfosTopSection_data.graphql";
import { PackagePage_viewerUser$data } from "@generated/PackagePage_viewerUser.graphql";
import { PackageSettingsGeneral_viewer$data } from "@generated/PackageSettingsGeneral_viewer.graphql";
import { bytesToSize, cn } from "@libs/helper";

import PackageName from "../PackageName";
import ConfidentialityTag from "./ConfidentialityTag";
import css from "./PackageInfosTopSection.module.css";
import PackageTag from "./PackageTag";
import PackageVersionsDropdownLazy from "./PackageVersionsDropdownLazy";
import WasmerRunCommandBtn from "./WasmerRunCommandBtn";

interface CustomButtonProps {
  children: React.ReactNode;
  className?: string;
  dataCy?: string;
  onClick?: () => void;
}
export const CustomButton: React.FC<CustomButtonProps> = ({ children, className, dataCy, onClick }) => {
  return (
    <button
      className={cn(
        "flex cursor-pointer items-center justify-center gap-3 rounded-[18px] border border-[#0F0518] border-opacity-20 px-4 py-1 text-[16px] font-[600] leading-[140%]",
        className
      )}
      onClick={onClick}
      data-cy={dataCy}
    >
      {children}
    </button>
  );
};

interface LikeBtnProps {
  likersCount: number;
  liked?: boolean;
  disableLike?: boolean;
  onClick?: () => void;
  className?: string;
}
export const LikeBtn: React.FC<LikeBtnProps> = ({
  likersCount,
  liked = false,
  disableLike = false,
  onClick,
  className,
}) => {
  return (
    <CustomButton
      className={clsx(
        {
          [css.hasLikedBtn]: liked,
          [css.defaultCursor]: disableLike,
          ["py-[5px] pl-[16px] pr-[18px]"]: likersCount > 0,
          ["px-[5px] py-[5px]"]: likersCount <= 0,
        },
        css.likeBtn,
        className
      )}
      dataCy="like-btn"
      onClick={onClick}
    >
      <Like className={clsx("h-[22px] w-[22px]", { [css.hasLiked]: liked })} />
      {likersCount > 0 && likersCount}
    </CustomButton>
  );
};
interface PackageNamePlusProps {
  name: string;
  owner: string;
  moduleInterfaces?: PackageInfosTopSection_data$data["moduleInterfaces"];
  version?: PackageInfosTopSection_data$data["version"];
  isPrivate?: PackageInfosTopSection_data$data["package"]["private"];
  className?: string;
  linking?: linkingType;
}
export const PackageNamePlus: React.FC<PackageNamePlusProps> = ({
  className,
  name,
  owner,
  moduleInterfaces,
  isPrivate,
  linking = "nolink",
  version,
}) => {
  return (
    <div className={cn("flex w-full flex-col justify-between", className)}>
      <div className="flex items-start justify-start gap-3">
        <PackageName name={name} owner={owner} className="text-[22px]" linking={linking} />
        {!moduleInterfaces?.length
          ? null
          : moduleInterfaces?.map((moduleInterface) => {
              const interfaceName = moduleInterface?.interface?.name;
              return (
                <Link href={`/interface/${interfaceName}`} key={interfaceName}>
                  <PackageTag content={interfaceName} />
                </Link>
              );
            })}
      </div>
      <div className="flex items-center justify-start gap-2">
        <ConfidentialityTag isPrivate={isPrivate} />
        <PackageVersionsDropdownLazy packageName={`${owner}/${name}`} version={version} />
      </div>
    </div>
  );
};

const PackageInfosTopSectionDataFragment = graphql`
  fragment PackageInfosTopSection_data on PackageInstance {
    # ...PackageVersionsDropdown_data
    package {
      ...CreateAppButtonPackage_data
      appTemplates {
        totalCount
      }
      id
      packageName
      icon
      owner {
        globalName
      }
      private
      likersCount
      viewerHasLiked
      showDeployButton
    }
    ... on PackageVersion {
      version
      fileSize
      moduleInterfaces {
        interface {
          name
        }
      }
    }
  }
`;

interface PackageInfosTopSectionProps {
  fragmentRef: PackageInfosTopSection_data$key;
  viewerData: PackageSettingsGeneral_viewer$data | PackagePage_viewerUser$data | null;
}
const PackageInfosTopSection: React.FC<PackageInfosTopSectionProps> = ({ viewerData, fragmentRef }) => {
  const data = useFragment(PackageInfosTopSectionDataFragment, fragmentRef);
  const router = useRouter(),
    searchParams = useSearchParams(), pathname = usePathname();

  const packageName = data?.package?.packageName,
    packageOwner = data?.package?.owner?.globalName,
    packageIcon = data?.package?.icon,
    isPackagePrivate = data?.package?.private,
    packageLikersCount = data?.package?.likersCount,
    packageModuleInterfaces = data?.moduleInterfaces,
    packageVersion = data.version,
    appTemplates = data.package.appTemplates?.totalCount!;

  const byteSize = data.fileSize ? bytesToSize(data.fileSize) : "";

  const wasmerRunCommand = `wasmer run ${packageOwner}/${packageName}`;
  const pkgHash = searchParams.get("packageName")?.split(":")[1] || undefined;

  const [likePkg, _likingPkg] = useMutation(graphql`
    mutation PackageInfosTopSection_LikeMutation($input: LikePackageInput!) {
      likePackage(input: $input) {
        package {
          id
          likersCount
          viewerHasLiked
        }
      }
    }
  `);

  const [unlikePkg, _unlikingPkg] = useMutation(graphql`
    mutation PackageInfosTopSection_UnlikeMutation($input: UnlikePackageInput!) {
      unlikePackage(input: $input) {
        package {
          id
          likersCount
          viewerHasLiked
        }
      }
    }
  `);

  const handleLikePackage = useCallback(() => {
    likePkg({
      variables: {
        input: {
          packageId: data?.package?.id,
        },
      },
      optimisticUpdater: (store) => {
        const record = store.get(data?.package?.id);
        if (!record) return;
        const currentLikeCount = (record.getValue("likersCount") as number) || 0;
        const viewerHasLiked = record.getValue("viewerHasLiked") || false;

        if (!viewerHasLiked) {
          record.setValue(currentLikeCount + 1, "likersCount");
          record.setValue(true, "viewerHasLiked");
        }
      },
    });
  }, [likePkg, data]);

  const handleUnlikePackage = useCallback(() => {
    unlikePkg({
      variables: {
        input: {
          packageId: data?.package?.id,
        },
      },
      optimisticUpdater: (store) => {
        const record = store.get(data?.package?.id);
        if (!record) return;
        const currentLikeCount = (record.getValue("likersCount") as number) || 0;
        const viewerHasLiked = record.getValue("viewerHasLiked") || false;

        if (viewerHasLiked) {
          record.setValue(currentLikeCount - 1, "likersCount");
          record.setValue(false, "viewerHasLiked");
        }
      },
    });
  }, [unlikePkg, data]);

  const toggleLikePkg = () => {
    if (!viewerData) {
      router.push(`/login?redirect=${pathname}`);
      return;
    }

    if (data?.package?.viewerHasLiked) {
      handleUnlikePackage();
    } else {
      handleLikePackage();
    }
  };
  return (
    <>
      <div className={css.wrapper} data-cy="package-page">
        {/* mobile version */}
        <div
          className="flex w-full flex-col items-start justify-center gap-4 tablet:hidden"
          data-cy="top-section-mobile"
        >
          <div className="flex w-full items-start justify-between gap-[40px]">
            <div className="flex items-start justify-center gap-4">
              <Image
                src={packageIcon}
                alt={packageName + " package icon"}
                width={48}
                height={48}
                className="col-start-1 row-start-1 max-h-[48px] rounded-[8px]"
                crossOrigin="anonymous"
              />
              <PackageNamePlus
                name={packageName}
                owner={packageOwner}
                moduleInterfaces={packageModuleInterfaces}
                isPrivate={isPackagePrivate}
                version={packageVersion}
                className="hidden mobile:flex"
                linking="owner-package"
              />
            </div>
            <LikeBtn
              likersCount={packageLikersCount}
              className="tablet:hidden"
              onClick={toggleLikePkg}
              liked={data?.package?.viewerHasLiked}
            />
          </div>
          <PackageNamePlus
            name={packageName}
            owner={packageOwner}
            version={packageVersion}
            moduleInterfaces={packageModuleInterfaces}
            isPrivate={isPackagePrivate}
            className="mobile:hidden"
          />
        </div>
        {/* tablet version */}
        <div className="hidden items-end justify-center gap-4 tablet:flex" data-cy="top-section-tablet">
          <Image
            src={packageIcon}
            alt={packageName + " package icon"}
            width={48}
            height={48}
            className="col-start-1 row-start-1 max-h-[48px] rounded-[8px]"
            crossOrigin="anonymous"
          />
          <div className="flex flex-col justify-between gap-[3px]">
            <div className="flex items-center justify-start gap-3">
              <h1>
                <PackageName
                  name={packageName}
                  owner={packageOwner}
                  className="flex-nowrap text-[22px]"
                  linking="owner-package"
                />
              </h1>
              {!packageModuleInterfaces?.length
                ? null
                : packageModuleInterfaces?.map((moduleInterface) => {
                    const interfaceName = moduleInterface?.interface?.name;
                    return (
                      <Link href={`/interface/${interfaceName}`} key={interfaceName}>
                        <PackageTag content={interfaceName} />
                      </Link>
                    );
                  })}
            </div>
            <div className="flex items-center justify-start gap-2">
              {!pkgHash && (
                <>
                  <ConfidentialityTag isPrivate={isPackagePrivate} />
                  <PackageVersionsDropdownLazy
                    packageName={`${packageOwner}/${packageName}`}
                    version={packageVersion}
                  />
                </>
              )}
              {pkgHash && (
                <>
                  <div className={css.pkgHashLabel}>{pkgHash.substring(0, 6)}</div>
                  <div className={css.pkgHashSize}>{byteSize}</div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="hidden w-full flex-wrap items-center justify-start gap-4 tablet:flex tablet:w-fit tablet:justify-end">
          <LikeBtn
            likersCount={packageLikersCount}
            onClick={toggleLikePkg}
            liked={data?.package?.viewerHasLiked}
            className=""
          />
          <div className="flex w-fit flex-1 gap-4 py-0 max-laptop:hidden max-mobile:w-full  max-mobile:justify-between">
            {/* {appTemplates > 0 && <CreateAppButton packageRef={data?.package} deploy />} */}
            {appTemplates > 0 && (
              <Link href={`/${packageOwner}/${packageName}/templates`}>
                <CreateAppButtonSilent />
              </Link>
            )}
            <WasmerRunCommandBtn wasmerRunCommand={wasmerRunCommand} />
          </div>
        </div>
      </div>
      <div className="flex max-w-full gap-4 py-0  laptop:hidden ">
        {appTemplates > 0 && (
          <Link href={`/${packageOwner}/${packageName}/templates`}>
            <CreateAppButtonSilent />
          </Link>
        )}{" "}
        {/* {appTemplates > 0 && <CreateAppButton packageRef={data?.package} deploy />}{" "} */}
        <WasmerRunCommandBtn
          wasmerRunCommand={wasmerRunCommand}
          className={clsx({ ["max-mobile:w-[calc(100%-118px)]"]: data.package.showDeployButton })}
        />
      </div>
    </>
  );
};

export default PackageInfosTopSection;
